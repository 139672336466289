export const crusaders = [
  {
    name: "Vainni Darkforge",
    description: `
You see a slender female here. She stands roughly 5 feet and 5 inches tall and is a lithe
figure of war. The hair that flows down to the middle of her back is a pale blonde in
color, almost to the point of being white. The hair itself is held into place by a leather
strap that ties the hair loosely right behind her neck. Her eyes are the color of a bright
emerald green and they seem to have their own shine to them. Her skin is silky smooth
and very light in color. At certain points on her body, you can see the blue veins that run
underneath her skin. Her shoulders and arms are quite slender but you can see the muscle
that ripples beneath the skin. She has a scar on her right forearm that is in a zigzag type
pattern. It appears to be a ghost of what it once was. Her wrists and hands are slender,
which is noticed because she wears much jewelry. Bracelets around the wrist and a ring
on every finger are how she stands now. Her fingernails are trimmed down as far as they
will go and so much skill in how they are taken care of. They have no color to them as far
as you can see. Her chest is small for one of her size, but she is still quite the feminine
figure. Her stomach is well taken care of also with muscles in groups that count to the
number of 6. Her legs are quite long and again slender. You can see the muscles that run
down her legs through the clothing she now wears. Around her left angle is another scar
that is very similar, if not the same, as the one on her right wrist.


There is a pair of crossed Battleaxes, dripping with blood, tattooed on her left forearm. The one on the left
appears to have a name on it. "Nordwolf, Forever watching over us" is what it reads. On the one on the right,
appears to have the crest of the Darkforge family.


There is a new scar that runs across the tattoo of the Battleaxes.. The scar appears to be blade marks that criss-cross.
The scar is jagged and several parts where it looks like whomever did it, stopped to rest before continuing it.
By the angle of the scar, it looks self inflicted.
    `
  },
  {
    name: "Orin Darkforge",
    description: `
Here stands a rather thick musled dwarf with a freshly bald head. In the
middle of his face is a large thick crooked nose. His eyes are a bright blue.
About his mouth is a full thick beeard that reaches down to his belt. His
shoulders being so large make it hard for this rotund dwarf to enter doorways.
His arms and legs are thick with bulging muscle that bounce as he moves.
On his left forarm is a tattoo of an anvil surrounded by a reddish pink flame.
Upon closer look you can see etched on the side of the anvil is two crossed
warhammers that are surrounded by a purple flame. Upon his left forearm tis
a tattoo of a dagger with blood dripping off the tip and pooling underneath the
tip that seems to have faded slightly. On the left side of his neck tis a tattoo of
a massive Broadsword. Its blade neatly polished and its hilt made of gold out
fitted with precious dwarven gems that also has faded. On his left upper arm
is a tattoo of a massive Warhammer. The head is a bright silver surrouned by
a blue aura. The shaft  is of oak and is wrapped in a yellow ribbon thus
making the hand grip. This tattoo also seems faded. Upon his back is a large
battleaxe with bright golden blade with the edges dripping with blood. Etched
into the blade on the left tis Honor and on the blade ta the right Purity. The
shaft is of oak carved with ancient dwarven symbols. Upon the handle burned
into the shaft is WARLORD.
    `
  },
  {
    name: "Viddikren",
    description: `
White leathery wings flutter stirring up dust and dirt around this creature. A
large snout protrudes from his skull. Scaly lips part displaying teeth like
tiny pearl daggers. A forked tongue flickers at you and then disappears
back inside his mouth. Cold green eyes like smashed ovals surrounded in a
sea of white stare back at the world. His gaze is fixed upon nothing yet
seems to be absorbing everything. His entire body is covered with sickly
white scales that seem to swallow the light. A long neck snakes its way
down to find a broad torso. This creatures short arms contain great
strength and speed and end in deadly talons. His torso is well protected by
a bone plate that covers its entirety. Short legs extend from his waist
balancing him well in spite of their frequent inactivity. Sinister spikes
extend wickedly out of his reptilian spine from the peek of his head to the
tip of his tail.

Upon his left arm there is a tattoo of a dagger wrapped in
vines like a tribal wrapping around his arm.
    `
  },
  {
    name: "Vrothgard Ridgeback",
    description: `
An aura of confidence radiates from this warrior beast. A Minotaur, large for his kin,
he stands at an impressive nine feet in height with his shoulders being half that across.
The thick fur covering his arms, legs, and back is dark black and trimmed short and neat.
There is a strip of fur, slightly coarser, which runs against the rest of his back fur.
The fur on his broad chest and muscular stomach is long and wild, pure white in colour.
The long, thick fingers of his hand bear many scars; The skin leathered and conditioned
from years of combat. Atop his large head are two massive horns, with various runes and
marks of battle carved into them. His eyes are large, a hunter's eyes, and always alert
to his surroundings. His beard is dark black with streaks of white, and braided into three
strands. Across his right cheek, just above the jawline, you can see a fresh wound. It
is in the early stages of healing. It appears to be a thick red line going from his chin
to his temple. The surrounding skin is black tinged with green. Along the inside of his
left forearm is a tattoo of a long dagger, with an ornate handle. The word DAGGER is etched
into the steel. On his right arm, there is a tattoo of a large broadsword. The hilt is
massive, taking up his entire shoulder. The blade extends down his arm all the way to the
wrist. The word BROADSWORD is etched into the blade, reading from top to bottom. Across
his broad back is a tattoo of a large warhammer. The head is placed over his right shoulder
blade, with the haft extending to his lower back. The word WARHAMMER is carved into the haft.
Across the vast majority of his broad chest and muscular stomach is a tattoo of a large
shield, inscribed with great detail. Emblazoned upon the top of shield, just below his neck
is the word ENVOY. Wreathed through the letters are vines and flowers, which trail along the
edges of the shield.
    `
  },
  {
    name: "Wojciecha Haar-Meggido",
    description: `
Striking auburn locks, clean and well groomed cascade to shoulder length
and seem to glisten in the light.  Fierce blue eyes contrast the locks and
the fire burning within rivals the very sun.  A sculpted face, proportioned
as if created by the hand of a skilled artisan, carries a soft expression.
The feminine lips and high cheeks add a delicate appearance which is offset
by an athletic build, a seemingly aesthetic figure of a woman.  Demure
in stature and overbalanced by a confident air, this woman has tan skin
which looks soft, a small dagger is inked upon left calf, delicate yet
crafted tattoo of a broadsword.  It appears to be of elven craft.  It
too is equally as sharp in appearance.  On her left forearm is a dwarven
Warhammer, the head is depicted with a blue-green aura.  upon her
right forearm is the shield and quill which signifies the of Envoy
of the Crusade.  Delicately worked in the skin of her cheek is a small mark
which seems to be a part of her, A Lemniscate which glows softly with a
blue-green hue.
    `
  },
  {
    name: "Woldrun",
    description: `
A very well-defined musculature marks this cloud giant as an adventurer.
Likely of one of the fighting guilds, unless you miss your guess. His head is
shaved completely bald, save for a topknot situated toward the rear apex
of his skull. From there, long white hair issues forth down to his shoulders.
He has the pale skin of most of his race, marred in several spots by scars, a
common affliction among adventurers. His eyes are a faint blue, the color of
extremely shadow water in a snowy valley. His face has a grim cast to it,
but his features are well-formed and quite symmetrical. It is said that the
Cloud Giant villages and gathering places do not have such things as
performers, but the beauty of this male is definitely striking. Added to
the thick slabs of muscle he has gained from his adventures, he makes quite
an impression. Plus, you can see that...

Etched neatly on his right bicep you notice an ornate dagger.
Upon his left bicep you see a mighty skwirl holding a broadsword.
Upon his right bicep you see a rather large bunny wielding a warhammer.
Encircle about his neck you make out a blue rose encircled about a warhammer.
    `
  },
  {
    name: "Ysbaddaden",
    description: `
A monstrously tall man is before you, blue in hue. The skin is
pristinely clean, as if freshly washed, or doused in snow. The blue it
is is almost indescripable, a light huge, pristine and purre, not quite
so light as the sky, but certainly no ordinary blue. The creatures hair
is long and mottled, tangled in with itself around the creature's head
and cut raggedly with a knife over the eyes. The eyes themselves are
remarkable, as if they were two miniature seas, swirling with blues,
light and dark, as they look out with awareness and perception. Below
the creatures face a small smile can be seen, revealing crooked and
broken teeth. Below that, the prominance of his chin has been masked by
a full beard, which seems to be slightly more well-kempt than the hair,
as if it had been brushed. on each side of the chin, leading down from
the lines of his mouth, two massive braids can be seen, securing what
would elsewise be a cumbersome length, the braids trailing down to his
mid-torso. The being is clothed in a variety of white skins, which
similarly have the appearance of recently being cleaned, and are only
moderately dirtied.
On his left cheek, just underneath the eye, you notice a sort of tribal tattoo, that of a dagger, placed as a teardrop.
Crossing the dagger is a similarly made mark, that of a broadsword, particularly of note because it goes over his eyelid.
`
  },
  {
    name: "Yuja Lightwood",
    description: `
Before you, clad in the wears of what looks to be a skilled
fighter is Yuja. She is not overweight, not to skinny, but toned
to every proportion of her body, forming an hourglass shape,
as her long legs hold her body steady. The focus from her
purple eyes is intense, scanning every part of an object or
observing every movement of her prey. Her eyebrows furrow
making her face seem even more angry at times. The smile is
plain, seeming as if it were a constant mask. All this is
complimented with long tendrils of elegant silver hair to fall
just to the middle of her back. Upon her neck you read a
a black inked tattoo "silence". A large broadswoard is upon
the background of the tattoo upon her neck. It's handle
is etched with the eyes of a dragon, and a purple ribbon
symbolizing something from past. Whenever she breathes
the broadswoard seems to fluently move with her neck
as if it is alive. At the bottom of the sword is many tattoo's
of flowers , and sunsets, and five names inscribed into her
skin with VERY shiny gold writing, "To my brothers in arms,
Bedros, Deante, Osric, Restacin, and my lovely Sister Panthea."
You notice under all of these names, one strikes out the most
with ink the color of the brightest purple thera may find. The
name is wide, and tall, "Coleman Lightwood" it says, and in
small writing undernearth it saying, "Eternally Yours, Forever."
A small golden chain bearing a LARGE platnium ring inset with
green quartz. You notice the abdomen of this woman and see
a very large Warhammer it's handle etched with the pictures
of her ancestors. It's massive head reaches out almost as if
waiting to crush someone. The hands and feet of this woman
seem to be covered in many tattoo's of small green daggers, looking
as they are being unleashed from her body when she moves.
An elegant belt covers her waist glad with the words,
"Honor and Glory", with a large silver dragon gripping his
talons around the words.
    `
  },
  {
    name: "Deante",
    description: `
Standing before ye is a male human, with golden blonde
Hair, his eyes are a pale blue where is skin tone is
quite pale in comparison to his hair coloring.
Under his left eye there is a small yet visible
white marking protruding from his cheek.
He wields a large yet slim staff within his hands.
His chest is well defined, and about his waist
he wears a belt with many diffrent satchels
to which hold only what yer mind couldst imagine.
Tattooed in a green ink there is a large dagger
set onto the left side of his face.`
  },
  {
    name: "Restacin Goat",
    description: `
Standing about five feet tall, this human seems at peace
with himself and his surroundings.  His dark hair is cut short
his skin, light.  His eyes seem to be an odd whitish blue color.
Wearing a short brown robe, sleeves cut off, he clearly works
for a living.  Well muscled and toned, his simple appearance tends
to show a touch of the monastery.

Upon his right shoulder, you see a bejeweled dagger tattooed in green ink.
Around the dagger is a larger tattoo, a broadsword encompassing the dagger as its hilt.
    `
  },
  {
    name: "Panthea Neverbourne",
    description: `
Here stands a humanoid beast with a gigantic bear head that is covered in black
fur. Her large eyes are crimson red. There is no neck to speak of. She has large
broad shoulders that are packed with large thick muscle. Her chest is thick and
packed with large thick bounceing muscles. She has long thick arms that reach
her knees. Her hands look like bear paws with long black claws. Her waist is thick.
She has two large bulging legs. Her feet look like bear paws with long black claws.
About her chest and back is covered in white fur. From her waist to his feet is
covered in black fur. Burnt into the fur is a tattoo of a golden WARHAMMER with
the word Nature etched in blue letters. The handle of the warhammer is wrapped in
a thorny stem with a deep blue rose.
`
  },
  {
    name: "Uthbert Goat",
    description: `
Before you is a fanstastically odd creature, seeming to be a mix between a large
lion and with the head of an eagle.  A sharp, observant eyes look past a long face,
helmed in grey.  Silver feathers cover what you can see of its avian features, save for
its wings, which are oddly scaled like a dragon's, silver and obsidian overlapping. While its
feline half is covered in mottled silver and black fur, similar in kind to its wings. A long,
tuffed tail can be seen behind the creature, and its claws twinkle as the light catches their razor
sharp edges.  On its right wrist it has a colorful dagger tattooed beneath his claw.
The dagger is encased in a bejeweled broadsword, the tattooed gems looking almost real.
On the Griffon's left wrist is a wicked looking Warhammer, its handle glistening with gems.
Below the tattoo of the Warhammer, coming out of its hilt, is a wicked looking Battleaxe,
sparkling with scaled gems and making the two marks, as a whole, a nasty weapon combination.
The back of the creature is easily seven feet high, making it quite a nasty foe or war mount.
    `
  }
].reduce((results, entry) => {
  const key = entry.name.split(" ")[0].toLowerCase();
  results[key] = {
    ...entry,
    location: "/valhalla/keep/bar",
    exit: "The Crusader Bar"
  };
  return results;
}, {});

export default crusaders;
